<template>
  <div v-if="isAuthenticated">
    <div class="grid-x grid-padding-x">
      <div class="cell callout primary"><h3><i class="fi-megaphone"></i>&nbsp;&nbsp;&nbsp;Aktuelles</h3>
      </div>
    </div>

    <div class="grid-x grid-padding-x grid-padding-y">
      <div class="cell">
        <md-card class="fit-content">
          <md-tabs id="organization-tabs-id" md-dynamic-height @md-changed="onTabChanged">
            <md-tab id="tab-bulletin-board-id" md-label="Schwarzes Brett">
              <ParentsBulletinBoard></ParentsBulletinBoard>
            </md-tab>
            <md-tab id="tab-parental-letter-id" md-label="Elternbriefe">
            </md-tab>
            <md-tab id="tab-survey-id" md-label="Umfragen">
            </md-tab>
          </md-tabs>
        </md-card>
      </div>
    </div>
  </div>
</template>


<script>
import Spinner from 'vue-simple-spinner';
import jQuery from "jquery";
import ParentsBulletinBoard from '../components/views/parents/ParentsBulletinBoard';

export default {
  name: 'ParentsNews',
  components: {
    'vue-simple-spinner': Spinner,
    ParentsBulletinBoard
  },

  mounted() {
    setTimeout(() => jQuery(document).foundation(), 1000);
  },

  data() {
    return {
      sending: false,
      success: false,
      successMsg: '',
      error: false,
    }
  },

  methods: {

    onTabChanged(tabId) {
      console.log('id ' + tabId);
    },
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.authenticated
    },

    isParent() {
      return this.$store.getters.parent
    },

    isBeta() {
      return this.$store.getters.beta
    },

    labels() {
      return this.$store.getters.labels
    },
  }
}
</script>

<style lang="scss">

.fit-content {
  min-height: 580px;
}

</style>
