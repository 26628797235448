<template>
  <div v-if="isAuthenticated">
    <div class="grid-x grid-padding-x grid-padding-y">
      <div v-if="availableFacilities && availableFacilities.length > 0" class="cell medium-6 large-6">
        <md-field>
          <label>Einträge für Einrichtungen</label>
          <md-select v-model="selectedFacilities"
                     :disabled="sending || availableFacilities.length <= 1" multiple>
            <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                       :key="facility.referenceNumber">
              {{ facility.name }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <div class="cell medium-6 large-6">
        <md-button v-if="isAdmin" @click="onAddBulletin"
                   class="md-icon-button md-raised md-primary">
          <md-icon>note_add</md-icon>
          <md-tooltip>Neues Rundschreiben erstellen</md-tooltip>
        </md-button>
      </div>
    </div>

    <md-card class="md-card-flat">
      <md-card-expand>
        <md-card-actions md-alignment="left">
          <md-card-expand-trigger>
            <md-button id="open-current-button-id" class="md-icon-button">
              <md-icon>keyboard_arrow_down</md-icon>
              <md-tooltip>Zeige aktuelle Rundschreiben</md-tooltip>
            </md-button>
          </md-card-expand-trigger>
          <div class="md-title">
            <span style="padding-left: 1rem; padding-right: 1rem;">Aktuell</span>
            <span style="color: dodgerblue"><b>{{getCurrentBulletinMsgCount()}}</b></span>
          </div>
        </md-card-actions>

        <md-card-expand-content>
          <md-card-content>
            <div v-if="bulletinBoardMessages.length > 0">
              <div class="grid-x grid-padding-x grid-padding-y">
                <div v-for="bulletin of bulletinBoardMessages" v-if="!bulletin.archived && bulletinVisible(bulletin)"
                     :key="'bulletin-' + bulletin.refNr"
                     class="cell large-4 medium-6 small-12">
                  <BulletinCard :bulletin="bulletin"
                                :availableFacilities="availableFacilities"
                                @onEditBulletin="onEditBulletin"
                                @onDeleteBulletin="onDeleteBulletin">
                  </BulletinCard>
                </div>
              </div>
            </div>
            <div class="md-subhead" v-else>
              Keine aktuellen Rundschreiben vorhanden.
            </div>
          </md-card-content>
        </md-card-expand-content>
      </md-card-expand>
    </md-card>

    <br>

    <md-card class="md-card-flat">
      <md-card-expand>
        <md-card-actions md-alignment="left">
          <md-card-expand-trigger>
            <md-button id="open-archive-button-id" class="md-icon-button">
              <md-icon>keyboard_arrow_down</md-icon>
              <md-tooltip>Zeige Rundschreiben Archiv</md-tooltip>
            </md-button>
          </md-card-expand-trigger>
          <div class="md-title">
            <span style="padding-left: 1rem; padding-right: 1rem;">Archiv</span>
            <span style="color: orchid"><b>{{getArchivedBulletinMsgCount()}}</b></span>
          </div>
          <vue-simple-spinner style="padding-left: 1rem" v-if="sending"></vue-simple-spinner>
        </md-card-actions>

        <md-card-expand-content>
          <md-card-content>
            <div v-if="archivedBulletinBoardMessages.length > 0">
              <div class="grid-x grid-padding-x grid-padding-y">
                <div v-for="bulletin of archivedBulletinBoardMessages" v-if="bulletin.archived && bulletinVisible(bulletin)"
                     :key="'archived-bulletin-' + bulletin.refNr"
                     class="cell large-4 medium-6 small-12">
                  <BulletinCard :bulletin="bulletin"
                                :availableFacilities="availableFacilities"
                                @onEditBulletin="onEditBulletin"
                                @onDeleteBulletin="onDeleteBulletin">
                  </BulletinCard>
                </div>
              </div>
            </div>
            <div class="md-subhead" v-else>
              Keine Rundschreiben im Archiv vorhanden.
            </div>
          </md-card-content>
        </md-card-expand-content>
      </md-card-expand>
    </md-card>

    <EditBulletinDialog :bulletinBoardMsg="bulletinBoardMsg"
                        :availableFacilities="availableFacilities"
                        :facilityReferences="selectedFacilities"
                        :mode="dialogMode"
                        :showDialog="showEditBulletinDialog"
                        @updateBulletinSuccess="updateBulletinSuccess"
                        @closeEditBulletinDialog="closeEditBulletinDialog"></EditBulletinDialog>

  </div>
</template>


<script>
import Spinner from 'vue-simple-spinner';
import jQuery from "jquery";
import BulletinBoardMessage from '../../../entities/BulletinBoardMessage';
import EditBulletinDialog from "../../dialogs/parents/EditBulletinDialog";
import HttpErrorHandler from "../../../services/HttpErrorHandler";
import OrganizationInfoService from "../../../services/OrganizationInfoService";
import BulletinCard from "../../cards/BulletinCard";

export default {
  name: 'ParentsBulletinBoard',
  components: {
    BulletinCard,
    EditBulletinDialog,
    'vue-simple-spinner': Spinner
  },

  mounted() {
    setTimeout(() => jQuery(document).foundation(), 1000);
    this.restoreUserSettings();
    this.reloadData();

    document.getElementById("open-archive-button-id").addEventListener("click", () => {
      this.expandArchive();
    });
  },

  data() {
    return {
      sending: false,
      success: false,
      successMsg: '',
      error: false,
      errorMsg: '',

      dialogMode: 'add',

      selectedFacilityReferenceNumber: null,
      availableFacilities: [],
      selectedFacilities: [],

      showEditBulletinDialog: false,
      bulletinBoardMsg: null,

      bulletinBoardMessages: [],
      archivedBulletinBoardMessages: [],

      currentExpanded: false,
      archiveExpanded: false,
    }
  },

  methods: {

    getArchivedBulletinMsgCount() {
      let count = 0;
      this.archivedBulletinBoardMessages.forEach(bulletin => {
        if (this.bulletinVisible(bulletin)) {
          count++;
        }
      });
      return count;
    },

    getCurrentBulletinMsgCount() {
      let count = 0;
      this.bulletinBoardMessages.forEach(bulletin => {
        if (this.bulletinVisible(bulletin)) {
          count++;
        }
      });
      return count;
    },

    expandArchive() {
      this.archiveExpanded = !this.archiveExpanded;
      this.updateArchive();
    },

    restoreUserSettings() {
      this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
      if (this.selectedFacilityReferenceNumber === '*') {
        this.selectedFacilityReferenceNumber = null;
      }
    },

    reloadData() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {
          this.buildAvailableFacilities();
          this.reloadOrganizationInfo(true);
          clearInterval(reloadIntervalId);
        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
          }
        }
      }, 250);
    },

    updateArchive() {
      if (this.archiveExpanded) {
        this.reloadOrganizationInfo(true);
      }
    },

    reloadOrganizationInfo(archived) {
      this.sending = true;
      OrganizationInfoService.getOrganizationInfo(archived, this.isParent)
          .then(response => {
            this.sending = false;
            this.updateBulletinSuccess(response.data, null);

            if (!this.currentExpanded) {
              jQuery('#open-current-button-id').trigger('click');
              this.currentExpanded = true;
            }
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Trägerdaten');
            this.sending = false;
          });
    },

    buildAvailableFacilities() {
      this.availableFacilities = [];
      this.getAvailableFacilities.forEach(facility => {
        if (facility.referenceNumber !== '*') {
          this.availableFacilities.push(facility);
        }
      })

      if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
        this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
        this.selectedFacilityName = this.availableFacilities[0].name;
      }

      this.selectedFacilities = [];
      this.availableFacilities.forEach(facility => {
        if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
          this.selectedFacilities.push(this.selectedFacilityReferenceNumber);
        }
      })

      if (this.isParent) {
        this.selectedFacilities = this.user.allowedFacilities;
      }
    },

    onAddBulletin() {
      this.dialogMode = 'add';
      this.bulletinBoardMsg = JSON.parse(JSON.stringify(BulletinBoardMessage.BulletinBoardMessage));
      this.bulletinBoardMsg.facRefs = this.selectedFacilities;
      this.showEditBulletinDialog = true;
    },

    onEditBulletin(bulletinBoardMessage) {
      this.dialogMode = 'update';
      this.bulletinBoardMsg = JSON.parse(JSON.stringify(bulletinBoardMessage));
      this.showEditBulletinDialog = true;
    },

    onDeleteBulletin(bulletinBoardMessage) {
      this.dialogMode = 'delete';
      this.bulletinBoardMsg = JSON.parse(JSON.stringify(bulletinBoardMessage));
      this.showEditBulletinDialog = true;
    },

    bulletinVisible(bulletin) {
      let isVisible = false;
      this.selectedFacilities.forEach(fac => {
        bulletin.facRefs.forEach(ref => {
          if (fac === ref) {
            isVisible = true;
          }
        })
      })
      return isVisible;
    },

    updateBulletinSuccess(data, message) {
      this.bulletinBoardMessages = [];
      this.archivedBulletinBoardMessages = [];
      data.bulletinBoardMessages.forEach(msg => {
        if (msg.archived) {
          this.archivedBulletinBoardMessages.push(msg);
        }
        else {
          this.bulletinBoardMessages.push(msg);
        }
      });

      this.sortBulletinBoardMessages();
      if (message) {
        this.$store.commit('successMsg', message);
        this.success = true;
      }
    },

    closeEditBulletinDialog() {
      this.showEditBulletinDialog = false;
    },

    sortBulletinBoardMessages() {
      this.bulletinBoardMessages.sort((a, b) => {
        const dateOne = new Date(a.validFrom);
        const dateTwo = new Date(b.validFrom);
        return dateOne.getTime() - dateTwo.getTime()
      });

      this.archivedBulletinBoardMessages.sort((a, b) => {
        const dateOne = new Date(a.validFrom);
        const dateTwo = new Date(b.validFrom);
        return dateOne.getTime() - dateTwo.getTime()
      });
    },

  },

  computed: {

    dataAvailable() {
      return this.user && this.user.allowedFacilities && this.user.allowedFacilities.length > 0 &&
          this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated
    },

    isParent() {
      return this.$store.getters.parent
    },

    isAdmin() {
      return this.$store.getters.admin
    },

    isTreasurer() {
      return this.$store.getters.treasurer;
    },

    labels() {
      return this.$store.getters.labels
    },

    organization() {
      return this.$store.getters.organization;
    },

    user() {
      return this.$store.getters.user;
    },

    getAvailableFacilities() {
      return this.$store.getters.availableFacilities;
    },
  }
}
</script>

<style lang="scss" scoped>
.md-tooltip {
  font-size: medium;
}


</style>
