<template>
  <div v-if="bulletinBoardMsg" class="grid-x grid-padding-x">

    <div class="cell large-6 medium-12 small-12">
      <md-field :class="getValidationClass('caption')">
        <label>Überschrift</label>
        <md-input name="bulletinBoardMsg-caption" id="bulletinBoardMsg-name" autocomplete="off"
                  v-model="bulletinBoardMsg.caption" :disabled="sending || mode === 'delete'"/>
        <span class="md-error"
              v-if="!$v.bulletinBoardMsg.caption.required">Überschrift ist notwendig</span>
      </md-field>
    </div>

    <div class="cell small-12 medium-12 large-6">
      <div class="md-layout">
        <div class="md-layout-item" style="padding-right: 15px;">
          <md-datepicker id="valid-from-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="validFromDate"
                         md-immediately :disabled="sending || mode === 'delete'"
                         :class="getValidationClass('validFrom')">
            <label>Gültig von</label>
            <span class="md-error"
                  v-if="!($v.bulletinBoardMsg.validFrom.required)">Gültig von Datum ist notwendig</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item" style="padding-left: 15px;">
          <md-datepicker id="valid-until-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                         v-model="validUntilDate"
                         md-immediately :disabled="sending || mode === 'delete'"
                         :class="getValidationClass('validUntil')" @md-opened="onOpenValidUntilDatePicker">
            <label>Gültig bis</label>
            <span class="md-error"
                  v-if="!($v.bulletinBoardMsg.validUntil.required)">Gültig bis Datum ist notwendig</span>
          </md-datepicker>
        </div>
      </div>
    </div>

    <div class="cell small-12 medium-12 large-12">
      <md-field>
        <label>Inhalt</label>
        <md-textarea v-model="bulletinBoardMsg.text" maxlength="2000" style="height: 275px;"
                     :disabled="sending || mode === 'delete'"></md-textarea>
      </md-field>
    </div>

  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import jQuery from "jquery";
import Vue from "vue";

export default {
  name: 'bulletinBoardMsgForm',
  mixins: [validationMixin],

  props: {
    bulletinBoardMsg: {
      required: true
    },
    mode: {
      required: true
    },
    sending: {
      required: true
    },
  },

  created() {
    this.formatValidFromDate();
    this.formatValidUntilDate();
    this.initDatePickerSyncHandler(true);
  },

  beforeDestroy() {
    this.initDatePickerSyncHandler(false);
  },

  updated() {
    if (this.mode !== 'add') {
      this.validateBulletinBoardMsg();
    }
  },

  data: () => ({
    invalid: true,

    validFromDate: null,
    validUntilDate: null,
  }),

  validations: {
    bulletinBoardMsg: {
      caption: {
        required,
      },
      validFrom: {
        required,
      },
      validUntil: {
        required,
      },
    },
  },

  methods: {

    initDatePickerSyncHandler(init) {
      if (init) {
        setTimeout(() => {
          jQuery('#valid-from-date-dp').children('input').bind('blur', () => {
            this.validFromDate = jQuery('#valid-from-date-dp').children('input').val();
            this.writeBackDates();
          });
          jQuery('#valid-until-date-dp').children('input').bind('blur', () => {
            this.validUntilDate = jQuery('#valid-until-date-dp').children('input').val();
            this.writeBackDates();
          });
        }, 500);
      } else {
        jQuery('#valid-from-date-dp').children('input').unbind('blur');
        jQuery('#valid-until-date-dp').children('input').unbind('blur');
      }
    },

    formatValidFromDate() {
      if (this.validFromDate === null) {
        if (this.bulletinBoardMsg && this.bulletinBoardMsg.validFrom) {
          this.validFromDate = Vue.moment(this.bulletinBoardMsg.validFrom).format('DD.MM.YYYY');
        }
      }
    },

    formatValidUntilDate() {
      if (this.validUntilDate === null) {
        if (this.bulletinBoardMsg && this.bulletinBoardMsg.validUntil) {
          this.validUntilDate = Vue.moment(this.bulletinBoardMsg.validUntil).format('DD.MM.YYYY');
        }
      }
    },

    onOpenValidUntilDatePicker() {
      if ((this.bulletinBoardMsg.validFrom && !this.bulletinBoardMsg.validUntil) ||
          (this.validFromDate && !this.validUntilDate)) {
        this.bulletinBoardMsg.validUntil = this.bulletinBoardMsg.validFrom;
        this.validUntilDate = this.validFromDate;
      }
    },

    getValidationClass(fieldName) {
      let field = this.$v.bulletinBoardMsg[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    writeBackDates() {
      if (this.validFromDate) {
        this.bulletinBoardMsg.validFrom = Vue.moment(this.validFromDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      } else {
        this.bulletinBoardMsg.validFrom = null;
      }

      if (this.validUntilDate) {
        this.bulletinBoardMsg.validUntil = Vue.moment(this.validUntilDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      } else {
        this.bulletinBoardMsg.validUntil = null;
      }
    },

    validateBulletinBoardMsg() {
      this.$v.$touch();
      this.writeBackDates();
      this.invalid = this.$v.$invalid;
    },

  },

  computed: {
    labels() {
      return this.$store.getters.labels
    },
  },
};
</script>

<style lang="scss" scoped>
h5 {
  font-weight: 300;
}

.md-tooltip {
  font-size: medium;
  white-space: normal;
}

[type=button] {
  border-radius: 50%;
}
</style>

