<template>
  <div v-if="bulletinBoardMsg">
    <md-dialog :md-click-outside-to-close="false" id="createNewBulletinBoardMsgDialog" :md-active.sync="showDialog" @md-closed="closeDialog">
      <md-dialog-title>
        <form novalidate @submit.prevent="validateBulletinBoardMsg">

          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'update'" class="cell large-5 medium-10">
              <i class="fi-page-edit"></i>&nbsp;&nbsp;&nbsp;Rundschreiben bearbeiten
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-10">
              <i class="fi-page-add"></i>&nbsp;&nbsp;&nbsp;Neues Rundschreiben hinzufügen
            </div>
            <div v-else-if="mode === 'delete'" class="cell large-5 medium-10">
              <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Rundschreiben löschen
            </div>
            <div class="cell large-2 medium-2" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-5 medium-12 text-center">
              <div v-if="mode !== 'delete'">
                <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                  <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
                </button>
                <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                  <i class="fi-price-tag"></i>&nbsp;&nbsp;Hinzufügen
                </button>
                <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                        :disabled="sending">
                  <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
                </button>
              </div>
            </div>

            <div v-if="mode === 'delete'" class="cell text-center">
              <br>
              <md-content>Soll das folgenden Rundschreiben wirklich gelöscht werden?</md-content>
              <br>
                <button v-if="isAdmin" @click="onDeleteBulletin" class="button alert" :disabled="sending">
                  <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, <b>Rundschreiben</b> jetzt löschen
                </button>
                <button class="button success" style="margin-left: 1rem;"
                        @click="closeDialog" type="button" :disabled="sending">
                  <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
                </button>
            </div>

            <div v-if="availableFacilities && availableFacilities.length > 0" class="cell">
                <md-field>
                  <label>Rundschreiben sichtbar für folgende Einrichtungen</label>
                  <md-select v-model="bulletinBoardMsg.facRefs"
                             :disabled="sending || availableFacilities.length <= 1 || mode === 'delete'" multiple>
                    <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                               :key="facility.referenceNumber">
                      {{ facility.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
          </div>

        </form>
      </md-dialog-title>

      <md-dialog-content id="dialogContentId">
        <BulletinBoardMessageForm v-if="bulletinBoardMsg" ref="BulletinBoardMsgForm" :bulletinBoardMsg="bulletinBoardMsg" :sending="sending" :mode="mode">
        </BulletinBoardMessageForm>
        <md-progress-bar md-mode="indeterminate" v-if="sending"/>
      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>
</template>

<script>
  import HttpErrorHandler from '../../../services/HttpErrorHandler'
  import OrganizationInfoService from "../../../services/OrganizationInfoService";
  import BulletinBoardMessageForm from '../../forms/BulletinBoardMessageForm';
  import Spinner from 'vue-simple-spinner'
  import SessionTime from '../../animations/SessionTime';

  export default {

    name: 'EditBulletinDialog',

    components: {
      BulletinBoardMessageForm,
      'vue-simple-spinner': Spinner,
      SessionTime,
    },

    props: {
      bulletinBoardMsg: {
        required: true
      },
      availableFacilities: {
        required: true
      },
      facilityReferences: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      }
    },

    data: () => ({
      success: true,
      sending: false,
    }),

    methods: {

      addBulletinBoardMsg(bulletin) {
        this.sending = true;
        this.success = false;
        OrganizationInfoService.addBulletin(bulletin)
          .then((response) => {
            this.success = true;
            this.sending = false;
            this.$emit("closeEditBulletinDialog");
            this.$emit("updateBulletinSuccess", response.data,'Das Rundschreiben <b>' + this.bulletinBoardMsg.caption + '</b> wurde erfolgreich erstellt.');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen des Rundschreibens <b>' + this.bulletinBoardMsg.caption + '</b>');
            this.sending = false;
          })
      },

      updateBulletinBoardMsg(bulletin) {
        this.sending = true;
        this.success = false;
        OrganizationInfoService.updateBulletin(bulletin)
          .then((response) => {
            this.success = true;
            this.sending = false;
            this.$emit("closeEditBulletinDialog");
            this.$emit("updateBulletinSuccess", response.data,'Das Rundschreiben <b>' + this.bulletinBoardMsg.caption + '</b> wurde erfolgreich geändert.');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern des Rundschreibens <b>' + this.bulletinBoardMsg.caption + '</b>');
            this.sending = false;
          })
      },

      deleteBulletinBoardMsg(bulletin) {
        this.sending = true;
        this.success = false;
        OrganizationInfoService.deleteBulletin(bulletin.refNr)
          .then((response) => {
            this.success = true;
            this.sending = false;
            this.$emit("closeEditBulletinDialog");
            this.$emit("updateBulletinSuccess", response.data,'Das Rundschreiben <b>' + this.bulletinBoardMsg.caption + '</b> wurde erfolgreich gelöscht.');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Rundschreibens <b>' + this.bulletinBoardMsg.caption + '</b>');
            this.sending = false;
          })
      },

      onDeleteBulletin() {
        if (this.mode === 'delete') {
          this.deleteBulletinBoardMsg(this.bulletinBoardMsg);
        }
      },

      validateBulletinBoardMsg() {
        this.$refs.BulletinBoardMsgForm.validateBulletinBoardMsg();

        if (!this.$refs.BulletinBoardMsgForm.invalid) {
          if (this.mode === 'add') {
            this.addBulletinBoardMsg(this.bulletinBoardMsg);
          }
          if (this.mode === 'update') {
            this.updateBulletinBoardMsg(this.bulletinBoardMsg);
          }
        }
      },

      closeDialog() {
        this.$emit("closeEditBulletinDialog");
      }
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style>
</style>
