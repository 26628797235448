export default {

  name: 'BulletinBoardMessage',

  BulletinBoardMessage: {
    refNr: 0,
    validFrom: null,
    validUntil: null,
    facRefs: [],
    archived: false,
    caption: '',
    text: ''
  }
}