<template>
  <div v-if="bulletin">
    <md-card class="bulletin" md-with-hover>
      <md-card-header>
        <div class="md-subhead text-right">Rundschreiben vom <b>{{ bulletin.validFrom | moment('DD.MM.YYYY') }}</b>
          <span v-if="bulletin.archived" style="color: orchid;"> archiviert am <b>{{ addDay(bulletin.validUntil, 1)| moment('DD.MM.YYYY') }}</b></span>
          <span v-else style="color: seagreen;"> gültig bis <b>{{ addDay(bulletin.validUntil, 1)| moment('DD.MM.YYYY') }}</b></span>
        </div>
        <div class="md-subhead text-left facilities" style="max-height: 25px;overflow: auto;"><b>für</b> {{ getFacilityNames(bulletin.facRefs) }}
          <md-tooltip><b>Rundschreiben für</b><br>{{ getFacilityNames(bulletin.facRefs) }}</md-tooltip>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-10">
            <md-content class="md-scrollbar" style="max-height: 38px;overflow: auto;">
              <div class="md-title"><md-icon class="md-size-1-6x" md-primary>attach_file</md-icon>&nbsp;{{ bulletin.caption }}</div>
            </md-content>
          </div>
          <div class="cell small-2">
            <md-card-actions>
              <md-button v-if="isAdmin && !bulletin.archived" @click="onEditBulletin(bulletin)"
                         class="md-primary md-icon-button md-raised md-third">
                <md-icon>edit</md-icon>
                <md-tooltip>Rundschreiben bearbeiten</md-tooltip>
              </md-button>
              <md-button v-if="isAdmin" @click="onDeleteBulletin(bulletin)"
                         class="md-icon-button md-raised md-accent">
                <md-icon>delete</md-icon>
                <md-tooltip>Rundschreiben löschen</md-tooltip>
              </md-button>
            </md-card-actions>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <md-field v-if="bulletin && bulletin.text">
          <md-textarea v-model="bulletin.text" disabled></md-textarea>
        </md-field>
      </md-card-content>


    </md-card>
  </div>
</template>

<script>
  import ImageView from "@/components/views/ImageView";
  import ChildBriefRecord from "@/components/fabs/ChildBriefRecord";

  export default {
    name: 'BulletinCard',

    components: {
      ImageView,
      ChildBriefRecord,
    },

    props: {
      bulletin: {
        required: false
      },
      availableFacilities: {
        required: false
      }
    },

    methods: {

      onEditBulletin(bulletinBoardMessage) {
        this.$emit("onEditBulletin", bulletinBoardMessage);
      },

      onDeleteBulletin(bulletinBoardMessage) {
        this.$emit("onDeleteBulletin", bulletinBoardMessage);
      },

      addDay(dateStr, days) {
        let date = new Date(dateStr);
        date.setDate(date.getDate() + days);
        return date.toDateString();
      },

      getFacilityNames(facRefs) {
        if (this.availableFacilities) {
          let names = '';
          facRefs.forEach(ref => {
            this.availableFacilities.forEach(fac => {
              if (fac.referenceNumber === ref) {
                names += fac.name + ', '
              }
            })
          })
          return names.substr(0, names.length - 2);
        }
      },
    },

    computed: {

      isAdmin() {
        return this.$store.getters.admin
      },
    }
  }
</script>

<style lang="scss" scoped>

.bulletin {
  background: cornsilk;
}

.bulletin.md-theme-dark {
  background: #666666;
}

.md-card {
  height: 340px;
  cursor: text;
}

.md-card-content {
  height: 168px;
}

.md-content {
  max-height: 150px;
  overflow: auto;
  background: cornsilk;
}

.md-content.md-theme-dark {
  background: #666666;
}

.md-field {
  padding-top: 0;
}

.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  max-height: 196px;
  min-height: 196px;
  height: 196px;
  min-width: 100%;
  background: transparent;
  box-shadow: none;
  cursor: text;
  padding: 5px 10px;
}

.md-card-actions {
  padding: 0;
}

.md-theme-dark .md-textarea {
  color: whitesmoke;
}

.md-icon.md-size-2x {
  margin-top: -1px;
}

.md-size-1-5x {
  width: 35px;
  min-width: 35px;
  height: 35px;
  font-size: 35px!important;
  margin-top: -2px;
}

.md-subhead {
  padding-bottom: 5px;
}

.md-card.md-theme-default .md-card-expand .md-card-actions {
  background: cornsilk !important;
}

.md-card.md-theme-dark .md-card-expand .md-card-actions {
  background: #666666 !important;
}

.md-theme-default .facilities {
  color: rebeccapurple;
  opacity: 1;
}

.md-theme-dark .facilities {
  color: greenyellow;
  opacity: 1;
}

.md-card .md-title {
  line-height: 33px;
}

</style>
